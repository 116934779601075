import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { csrfCookie } from "../../api/api";
import { authenticate, refreshUser, revoke } from "../../api/auth";

const initialState = {
  user: {
    id: null,
    place: {
      id: null,
      place_code: null,
      place_name: null
    },
    staff_code: null,
    staff_name: null,
    staff_kana: null,
    email: null,
    role: {
      id: null,
      label: null,
      basename: null
    },
    created_at: null,
    updated_at: null
  },
  loading: false,
  checked: false,
  expired: false
};

export const loginCheck = createAsyncThunk("auth/check", async () => {
  await csrfCookie();
  return refreshUser();
});

export const login = createAsyncThunk("auth/login", async user => {
  return authenticate(user.username, user.password);
});

export const logout = createAsyncThunk("auth/logout", async () => {
  return revoke();
});

export const apiRequest = createAsyncThunk(
  "auth/api",
  async (promise, { rejectWithValue }) => {
    try {
      return await promise;
    } catch (e) {
      if (e.response) {
        const result = { status: e.response.status, data: e.response.data };
        return rejectWithValue(result);
      }
      return rejectWithValue({ data:{} });
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(login.pending, (state, action) => {
      return { ...state, loading: true };
    });
    builder.addCase(login.fulfilled, (state, action) => {
      const user = action.payload;
      return { ...state, loading: false, user };
    });
    builder.addCase(login.rejected, (state, action) => {
      return { ...state, loading: false };
    });
    builder.addCase(loginCheck.fulfilled, (state, action) => {
      const user = action.payload;
      return { ...state, user, checked: true };
    });
    builder.addCase(loginCheck.rejected, (state, action) => {
      return { ...state, checked: true };
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      return { ...initialState, checked: true };
    });
    builder.addCase(apiRequest.rejected, (state, action) => {
      if ([401, 419].includes(action.payload.status)) {
        return { ...state, expired: true };
      }
    });
  }
});

export default authSlice.reducer;
