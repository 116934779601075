import { apiGet, apiPost } from "./api";

export async function passwordResetRequest(email) {
  const response = await apiPost("/password-reset/request", { email });
  return response.data;
}

export async function passwordResetTokenValidate(token, email) {
  const response = await apiGet("/password-reset/validate", { token, email });
  return response.data;
}

export async function passwordReset(token, email, password) {
  const response = await apiPost("/password-reset/change", { token, email, password });
  return response.data;
}

export async function passwordResetRequestByUser(password) {
  const response = await apiPost("/password-reset/change-by-user", { password });
  return response.data;
}

