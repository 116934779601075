import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { newVideo, uploadVideo } from "../../api/video";

const initialState = {
  video: {
    id: null,
    basename: null,
    limit_days: null,
    limit_count: null,
    user: {
      id: null,
      place: {
        id: null,
        place_code: null,
        place_name: null
      },
      staff_code: null,
      staff_name: null,
      staff_kana: null,
      email: null
    },
    created_at: null,
    updated_at: null
  },
  uploading: false,
  completed: false,
  error: null,
  percentage: 0,
  loaded: 0,
  total: 0
};

export const createVideo = createAsyncThunk("upload/create", async () => {
  return newVideo();
});

export const upload = createAsyncThunk(
  "upload/send",
  async ({ video, file, onUploadProgress = e => {} }, { rejectWithValue }) => {
    try {
      return await uploadVideo(video, file, onUploadProgress);
    } catch (e) {
      if (e.response) {
        const data = e.response.data || {};
        const message = data.message || "";
        const result = { status: e.response.status, message };
        return rejectWithValue(result);
      }
      return rejectWithValue({ status: null, message: "通信エラー" });
    }
  }
);

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    progress: (state, action) => {
      const progress = action.payload;
      return {
        ...state,
        ...progress,
        percentage: Math.round((progress.loaded * 100) / progress.total)
      };
    },
    complete: state => {
      return { ...state, completed: true };
    },
    reset: state => {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder.addCase(createVideo.pending, (state, action) => {
      return { ...initialState, uploading: true };
    });
    builder.addCase(createVideo.fulfilled, (state, action) => {
      return { ...state, video: { ...action.payload } };
    });
    builder.addCase(createVideo.rejected, (state, action) => {
      return { ...state, uploading: false };
    });
    builder.addCase(upload.pending, (state, action) => {
      return { ...state, uploading: true };
    });
    builder.addCase(upload.fulfilled, (state, action) => {
      return { ...state, uploading: false, progress: 100 };
    });
    builder.addCase(upload.rejected, (state, action) => {
      return { ...state, uploading: false, error: action.payload.message };
    });
  }
});

export const { progress, complete } = uploadSlice.actions;

export default uploadSlice.reducer;
