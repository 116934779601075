import React, { useRef } from "react";
import { Form, FormLabel, Icon, InputContainer } from "uikit-react";
import { useNavigate } from "react-router-dom";
import UIkit from "uikit";
import { passwordResetRequest } from "../../api/password";

function PasswordResetRequestForm() {
  const navigate = useNavigate();
  const email = useRef();

  const onSubmit = e => {
    e.preventDefault();
    (async () => {
      passwordResetRequest(email.current.value)
        .then(() => {
          UIkit.notification({
            message: "メールを送信しました",
            status: "primary",
            timeout: 2000
          });
          navigate("/login");
        })
        .catch(e => {
          let message = "メールの送信に失敗しました";
          const data = e.response.data;
          if (data && data.message) {
            message = data.message;
          }
          UIkit.notification({
            message,
            status: "danger",
            timeout: 2000
          });
        });
    })();
  };

  return (
    <form action="" method="post" onSubmit={onSubmit}>
      <Form layout="stacked">
        <div className="uk-margin uk-margin-medium-bottom">
          <p>
            ご登録いただいているメールアドレス宛にパスワードのリセットメールを送信します。
          </p>
        </div>
        <div className="uk-margin">
          <InputContainer>
            <FormLabel className="uk-inline" width="1-1">
              <Icon className="uk-form-icon" options="user" />
              <input
                type="text"
                name="email"
                className="uk-input"
                autoComplete="email"
                placeholder="メールアドレス"
                ref={email}
              />
            </FormLabel>
          </InputContainer>
        </div>
        <div className="uk-margin-medium-top uk-text-center">
          <button type="submit" className="uk-button uk-button-primary">
            パスワードリセットメールを送信
          </button>
        </div>
      </Form>
    </form>
  );
}

export default PasswordResetRequestForm;
